import React from 'react';
import './Loader.css';

export const Loader = () => {
  return (
    <div>
      <div className={'overlayLoader'}></div>

      <div className="lds-dual-ring"></div>
    </div>
  );
};
