import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './DataTable.css';
import React, {useEffect, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {toast} from 'react-toastify';
import {PopupAction} from '../PopupAction/PopupAction';
import {Loader} from '../Loader/Loader';
import {DeletePopup} from '../DeletePopup/DeletePopup';
import {ReactComponent as RefreshSvg} from '../../assets/refresh.svg';
import {ReactComponent as PauseSvg} from '../../assets/pause.svg';
import {ReactComponent as PlaySvg} from '../../assets/play.svg';
import {ReactComponent as DeleteshSvg} from '../../assets/trash.svg';
import {Popup} from '../Popup/Popup';
import {ClustersAPI} from '../../api/api';

const mapToTableObject = clustersList => {
  return clustersList.map((el, index) => ({
    element: {id: index + 1, arn: el.arn},
    name: el.name,
    url: el.url,
    image: el.image,
    status: el.status ? {value: 'Up', statusBoolean: true} : {value: 'Down', statusBoolean: false},
  }));
};

const isItemHaveTrue = clusters => {
  const isTrueElement = clusters.find(el => el.status === true);

  return Boolean(isTrueElement);
};

// eslint-disable-next-line react/prop-types
export const Table = ({name, environment}) => {
  const [openPopup, setOpen] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [element, setElement] = useState({});

  const [clusters, setClusters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTrueElement, setStatusTable] = useState(true);
  const [actionType, setActionType] = useState({type: 'stop', text: ''});

  const getClustersList = async () => {
    setLoading(true);
    const res = await ClustersAPI.getClusters({environment});
    setClusters(mapToTableObject(res.status.message));
    setStatusTable(isItemHaveTrue(res.status.message));
    setLoading(false);
  };

  const confirmAction = async () => {
    setLoading(true);
    try {
      setOpenAction(false);
      await ClustersAPI.actionClusters({environment, action: actionType.type});
      getClustersList();
      toast.success(`${actionType.type} all environment`, {
        position: 'top-center',
        autoClose: 5000,
      });
    } catch (e) {
      setLoading(false);
      toast.error(e.message, {
        position: 'top-center',
        autoClose: 5000,
      });
    }
  };

  const updateStatus = async ({name, action}) => {
    setLoading(true);
    try {
      const res = await ClustersAPI.editStatusCluster({environment, name, action});
      setLoading(false);
      await getClustersList();
      toast.success(res.status.message, {
        position: 'top-center',
        autoClose: 5000,
      });
    } catch (e) {
      toast.error(e.message, {
        position: 'top-center',
        autoClose: 5000,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getClustersList();
  }, []);

  const actionTemplate = rowData => {
    return (
      <div className="actionGroup">
        <button
          className="btn"
          onClick={() => {
            updateStatus({
              name: rowData.name,
              action: rowData.status.statusBoolean ? 'stop' : 'start',
            });
          }}
        >
          {rowData.status.statusBoolean ? <PauseSvg /> : <PlaySvg />}
        </button>
        <button
          className="btn"
          onClick={() => {
            setOpenDeletePopup(true);
            setElement(rowData);
          }}
        >
          <DeleteshSvg />
        </button>
      </div>
    );
  };
  const urlTemplate = rowData => {
    return (
      <a target="_blank" rel="noreferrer" href={rowData.url} className={'urlField'}>
        {rowData.url}
      </a>
    );
  };

  return (
    <div>
      <div className="contentTable">
        <div className={'headerTable'}>
          <div className={'totalClusters'}>Total Clusters: {clusters.length}</div>
          <div className={'btnGroupTable'}>
            <button
              className={'restoreBtn'}
              onClick={() => {
                setActionType({
                  type: 'restore',
                  text: `Are you sure you want to restore all clusters in ${name} environment to the latest configuration?`,
                });
                setOpenAction(true);
              }}
            >
              Restore
            </button>
            {isTrueElement ? (
              <button
                className={'stopBtn'}
                onClick={() => {
                  setActionType({
                    type: 'stop',
                    text: `Are you sure you want to stop all clusters in ${name} environment?`,
                  });
                  setOpenAction(true);
                }}
              >
                Stop
              </button>
            ) : (
              <button
                className={'startBtn'}
                onClick={() => {
                  setActionType({
                    type: 'start',
                    text: `Are you sure you want to start all clusters in ${name} environment?`,
                  });
                  setOpenAction(true);
                }}
              >
                Start
              </button>
            )}
            <div className={'refreshData'} onClick={getClustersList}>
              <RefreshSvg />
            </div>
          </div>
        </div>
        {loading && <Loader />}
        <DataTable
          className="ttt"
          value={clusters}
          paginator
          paginatorTemplate=" PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={7}
        >
          <Column className="idColumn" field="element.id" header="#"></Column>
          <Column field="name" header="Name"></Column>
          <Column className="urlField" body={urlTemplate} header="URL"></Column>
          <Column field="image" header="Image"></Column>
          <Column field="status.value" header="Status"></Column>
          <Column
            className={'action'}
            body={actionTemplate}
            header="Actions"
            style={{textAlign: 'center', width: '6em'}}
          />
        </DataTable>
        {openPopup && (
          <Popup
            updateList={getClustersList}
            onClose={() => setOpen(false)}
            environment={environment}
            name={name}
          />
        )}
        {openDeletePopup && (
          <DeletePopup
            updateList={getClustersList}
            onClose={() => setOpenDeletePopup(false)}
            environment={environment}
            name={element.name}
          />
        )}
        {openAction && (
          <PopupAction onClose={() => setOpenAction(false)} onConfirm={confirmAction}>
            <div className={'messagePopup'}>{actionType.text}</div>
          </PopupAction>
        )}
        <div className={'createClusters'}>
          <button className={'btnCreateClusters'} onClick={() => setOpen(true)}>
            CREATE CLUSTER
          </button>
        </div>
      </div>
    </div>
  );
};
