import React, {useEffect, useState} from 'react';
import './WinTable.css';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {toast} from 'react-toastify';
import {ReactComponent as RefreshSvg} from '../../assets/refresh.svg';
import {ReactComponent as PauseSvg} from '../../assets/pause.svg';
import {ReactComponent as PlaySvg} from '../../assets/play.svg';
import {WindowsAPI} from '../../api/api';
import {Loader} from '../../components/Loader/Loader';

const mapToTableObject = clustersList => {
  return clustersList.map((el, index) => ({
    element: {id: index + 1, instance_id: el.instance_id},
    name: el.name,
    environment: el.environment,
    ip: el.private_ip_address,
    type: el.Instance_type,
    status: el.status ? {value: 'Up', statusBoolean: true} : {value: 'Down', statusBoolean: false},
  }));
};

export const WinTable = () => {
  const [winServers, setWin] = useState([]);
  const [loading, setLoading] = useState(false);

  const getClustersList = async () => {
    setLoading(true);
    try {
      const res = await WindowsAPI.getWin();
      setWin(mapToTableObject(res.status.message));
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const updateStatus = async (id, action) => {
    try {
      setLoading(true);
      const res = await WindowsAPI.updateStatus({id, action});
      setLoading(false);
      toast.success(res.status.message, {
        position: 'top-center',
        autoClose: 5000,
      });
    } catch (e) {
      toast.error(e.message, {
        position: 'top-center',
        autoClose: 5000,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getClustersList();
  }, []);

  const actionTemplate = rowData => {
    return (
      <div className="actionGroup">
        <button
          className="btn"
          onClick={() => {
            updateStatus(
              rowData.element.instance_id,
              rowData.status.statusBoolean ? 'stop' : 'start'
            ).then(() => getClustersList());
          }}
        >
          {rowData.status.statusBoolean ? <PauseSvg /> : <PlaySvg />}
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="contentTable">
        <div className={'headerTable'}>
          <div className={'totalClusters'}>Total Clusters: {winServers.length}</div>
          <div className={'refreshData'} onClick={getClustersList}>
            <RefreshSvg />
          </div>
        </div>
        {loading && <Loader />}
        <DataTable
          className="ttt"
          value={winServers}
          paginator
          paginatorTemplate=" PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={7}
        >
          <Column className="idColumn" field="element.id" header="#"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="environment" header="Environment"></Column>
          <Column field="ip" header="IP"></Column>
          <Column field="type" header="Instance Type"></Column>
          <Column field="status.value" header="Status"></Column>
          <Column
            className={'action'}
            body={actionTemplate}
            header="Actions"
            style={{textAlign: 'center', width: '6em'}}
          />
        </DataTable>
      </div>
    </div>
  );
};
