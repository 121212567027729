import './App.css';
import React, {Fragment, useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import {RoutesSelect} from './routes';
import {MenuBar} from './components/MenuBar/MenuBar';
import 'react-toastify/dist/ReactToastify.css';
import {CheckConnectivity} from './pages/CheckConnectivity/CheckConnectivity';
import {ConnectinAPI} from './api/api';

export const App = () => {
  const [isResponseStatusOk, setResponseStatus] = useState(false);
  const [isInnitApp, setInnitApp] = useState(false);

  const checkConnect = async () => {
    try {
      await ConnectinAPI.checkConnection();
      setResponseStatus(true);
    } catch (e) {
      setResponseStatus(false);
    }
  };

  useEffect(() => {
    checkConnect().then(() => setInnitApp(true));
  }, [isInnitApp]);

  return (
    <div className="App">
      <ToastContainer draggable={false} hideProgressBar limit={2} />
      {isInnitApp &&
        (isResponseStatusOk ? (
          <Fragment>
            <MenuBar />
            <div className="mainContent">
              <RoutesSelect />
            </div>
          </Fragment>
        ) : (
          <CheckConnectivity />
        ))}
    </div>
  );
};
