import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {ClustersAPI} from '../../api/api';
import styles from './Popup.module.css';

// eslint-disable-next-line react/prop-types
export const Popup = ({onClose, environment, updateList, name}) => {
  const [value, setValue] = useState('');

  const onChangeInput = e => {
    setValue(e.target.value);
  };
  const saveData = async () => {
    await ClustersAPI.addClusters({environment, name: value})
      .then(() => {
        onClose();
        updateList();
        toast.success(`cluster success added`, {
          position: 'top-center',
          autoClose: 5000,
        });
      })
      .catch(e => {
        toast.error(e.message, {
          position: 'top-center',
          autoClose: 5000,
        });
        onClose();
      });
  };

  return (
    <div className={styles.popupPage}>
      <div className={styles.overlayPopup} onClick={onClose}></div>
      <div className={styles.content}>
        <div className={styles.mainContent}>
          <div className={styles.text}>Create cluster on {name} Environment</div>
          <div className={styles.nameInput}>
            <div className={styles.name}>Name:</div>
            <div className={styles.inputField}>
              <input value={value} onChange={onChangeInput} />
            </div>
          </div>
        </div>
        <div className={styles.btnGroup}>
          <button className={styles.cancelBtn} onClick={onClose}>
            CANCEL
          </button>
          <button className={styles.saveBtn} onClick={saveData}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
