import React from 'react';
import styles from './PopupAction.module.css';

// eslint-disable-next-line react/prop-types
export const PopupAction = ({onClose, onConfirm, children}) => {
  return (
    <div className={styles.popupPage}>
      <div className={styles.overlayPopup} onClick={onClose}></div>
      <div className={styles.content}>
        <div className={styles.mainContent}>{children}</div>
        <div className={styles.btnGroup}>
          <button className={styles.cancelBtn} onClick={onClose}>
            CANCEL
          </button>
          <button className={styles.saveBtn} onClick={onConfirm}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
