import React from 'react';
import './Win.css';
import {WinTable} from '../../components/WinTable/WinTable';

export const Win = () => {
  return (
    <div className={'winPage'}>
      <div className={'winServers'}>Win servers</div>
      <div className={'content'}>
        <WinTable />
      </div>
    </div>
  );
};
