import React from 'react';
import {toast} from 'react-toastify';
import styles from './DeletePopup.module.css';
import {ClustersAPI} from '../../api/api';

// eslint-disable-next-line react/prop-types
export const DeletePopup = ({onClose, name, environment, updateList}) => {
  const deleteCluster = async () => {
    await ClustersAPI.deleteClusters({environment, name})
      .then(() => {
        onClose();
        updateList();
        toast.success(`cluster success delete`, {
          position: 'top-center',
          autoClose: 5000,
        });
      })
      .catch(e => {
        toast.error(e.message, {
          position: 'top-center',
          autoClose: 5000,
        });
        onClose();
      });
  };

  return (
    <div className={styles.deletePopup}>
      <div className={styles.overlayPopup} onClick={onClose}></div>
      <div className={styles.content}>
        <div className={styles.confirmMessage}>Are you sure?</div>
        <div className={styles.btnGroup}>
          <button className={styles.cancelBtn} onClick={onClose}>
            CANCEL
          </button>
          <button className={styles.saveBtn} onClick={deleteCluster}>
            YES
          </button>
        </div>
      </div>
    </div>
  );
};
