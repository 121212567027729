import axios from 'axios';

export class BaseApi {
  static apiHostName = `https://api.main.clinicult.net`;

  static post(route, body) {
    return axios.post(`${this.apiHostName}${route}`, body);
  }
  static delete(route, body) {
    return axios.delete(`${this.apiHostName}${route}`, {data: body});
  }
  static put(route, body) {
    return axios.put(`${this.apiHostName}${route}`, body);
  }

  static get(route) {
    return axios.get(`${this.apiHostName}${route}`);
  }
}

export class ConnectinAPI extends BaseApi {
  static checkConnection() {
    return this.get(`/`).then(response => response.data);
  }
}

export class ClustersAPI extends BaseApi {
  static getClusters({environment = 'test'}) {
    return this.get(`/clusters/${environment}`).then(response => response.data);
  }
  static addClusters({environment = 'test', name = ''}) {
    return this.post(`/clusters/${environment}`, {name}).then(response => response.data);
  }
  static editStatusCluster({environment, name, action}) {
    return this.post(`/cluster`, {environment, name, action}).then(response => response.data);
  }
  static deleteClusters({environment, name}) {
    return this.delete(`/cluster`, {environment, name}).then(response => response.data);
  }
  static actionClusters({environment = 'test', action = 'stop'}) {
    return this.post(`/environment/${environment}`, {action});
  }
}

export class WindowsAPI extends BaseApi {
  static getWin() {
    return this.get('/windows').then(response => response.data);
  }
  static updateStatus({id, action}) {
    return this.post(`/windows-server/${id}`, {action}).then(response => response.data);
  }
}

export class LoginApi extends BaseApi {
  static login({user, password}) {
    return this.post('/login', {user, password}).then(response => response.data);
  }
}
