import React, {Fragment} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Login} from './pages/Login/Login';
import {Win} from './pages/Win/Win';
import {About} from './pages/About/About';
import {Main} from './pages/Main/Main';

const isAuthorization = true;

export const RoutesSelect = () => {
  return (
    <Switch>
      {isAuthorization ? (
        <Fragment>
          <Route exact path={`/about`} component={About} />
          <Route exact path={`/main`} component={Main} />
          <Route exact path={`/win`} component={Win} />
          <Redirect to={'/main'} />
        </Fragment>
      ) : (
        <Fragment>
          <Route exact path={'/login'} component={Login} />
          <Redirect to={'/login'} />
        </Fragment>
      )}
    </Switch>
  );
};
