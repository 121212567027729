import React from 'react';
import {TabPanel, TabView} from 'primereact/tabview';
import './Main.css';
import {Table} from '../../components/DataTable/DataTable';

export const Main = () => {
  return (
    <div className={'mainPage'}>
      <div className={'environments'}>Environments</div>
      <div className={'content'}>
        <TabView className={'tabPanel'}>
          <TabPanel header="Testing" headerClassName={'tabPanel'}>
            <Table name="Testing" environment="test" />
          </TabPanel>
          <TabPanel header="Staging">
            <Table name="Staging" environment="stage" />
          </TabPanel>
          <TabPanel header="Production">
            <Table name="Production" environment={'prod'} />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};
