import React from 'react';
import {NavLink} from 'react-router-dom';
import styles from './MenuBar.module.css';

export const MenuBar = () => {
  return (
    <div className={styles.menuBar}>
      <div className={styles.projectName}>Clinicult</div>
      <div className={styles.navigation}>
        <NavLink to={'/main'} className={styles.mainPage} activeClassName={styles.active}>
          Main
        </NavLink>
        <NavLink to={'/win'} className={styles.winPage} activeClassName={styles.active}>
          Win servers
        </NavLink>
        <NavLink to={'/about'} className={styles.aboutPage} activeClassName={styles.active}>
          About
        </NavLink>
      </div>
      <div className={styles.footer}>V 1.1</div>
    </div>
  );
};
