import React from 'react';
import styles from './About.module.css';

export const About = () => {
  return (
    <div className={styles.aboutPage}>
      <div className={styles.titleAbout}>About</div>
      <div className={styles.descriptions}>Clinicult.net</div>
    </div>
  );
};
